import { ModifyMode } from "../constants";
import { DateHelper } from "../helpers/dateHelper";
import { Product } from "../interfaces/product";
import { SaleItem } from "../interfaces/saleItem";
import { LocalStorage } from "../localStorage";
import store from "../store";
import { Settings } from "./configService";
import { CrudService } from "./crudService";
import { BookingPaymentType } from "../constants";
import Vue from "vue";

export class ShopService {

    async addProductToCart(product: Product, units: number, dom: any) {
        const s: SaleItem = {
            id: 0,
            productId: product.id,
            buyerCustomerId: this.getLoggedCustomer(),
            customerId: this.getLoggedCustomer(),
            saleId: 0,
            units: units,
            temp: true,
            subTotal: product.grossPrice * units,
            totalWithDiscount: product.grossPrice * units,
            netTotalWithDiscount: product.netPrice * units,
            product: product,
            taxPercent: 21, // just needed for post
            buyerCustomerTypeName: "-",// just needed for post
            customerTypeName: "-",// just needed for post
            parentIdentifier: product.name, // todo set when bookings or other things
        };
        
        if (product.hasStock === true) {
            s.stockParentId = s.productId;
            s.stockParentName = "Product";
        }

        await this.addToCart(s, dom);
    }

    animationToCart(elem: any) {
        if (elem === null || elem === undefined) {
            return;
        }
        const div = document.createElement("div");
        div.classList.add("animationCart");

        const viewportOffset = elem.getBoundingClientRect();
        const x = viewportOffset.top + window.scrollY;
        const y = viewportOffset.left + + window.scrollX;
        div.style.top = x + "px";
        div.style.left = y + "px";

        const app = document.getElementById("app");
        if (app == null) {
            return;
        }
        app.appendChild(div);

        const target: any = document.getElementById("menuShopCart");
        const tviewPort = target.getBoundingClientRect();
        const xT = tviewPort.top + window.scrollY;
        const yT = tviewPort.left + + window.scrollX;

        div.style.left = yT + 'px';
        div.style.top = xT + 'px';

        setTimeout(function () {
            if (div.parentNode === null) {
                return;
            }
            div.parentNode.removeChild(div);
        }, 1000);
    }

    private createSaleGroup(sale: any) {
        sale.saleIds = [sale.id];
        sale.cancelUrls = [];
        if (sale.cancelUrl) {
            sale.cancelUrls.push(sale.cancelUrl);
        }
        return sale;
    }

    private addSaleToGroup(sale: any, group: any) {
        group.saleIds.push(sale.id);
        if (sale.cancelUrl) {
            group.cancelUrls.push(sale.cancelUrl);
        }
        group.pending += sale.pending;
        group.netPending += sale.netPending;
        group.paid += sale.paid;
        group.total += sale.total;
        group.netTotal += sale.netTotal;
        group.grossTotalWithDiscount += sale.grossTotalWithDiscount;
        group.netTotalWithDiscount += sale.netTotalWithDiscount;
    }

    checkMixedPaymentTypes(cart: any, sale: any) {
        if (sale.paymentType === BookingPaymentType.Shared) {
            if (cart.sales.length > 0) {
                store.commit("openGlobalError",
                    Vue.prototype.$t("Shared payment can't be mixed with other sales Please, finalize the current cart and try again"));
                return false;
            }
        }
        else {
            if (cart.sales.length === 1 && cart.sales[0].paymentType === BookingPaymentType.Shared) {
                store.commit("openGlobalError",
                    Vue.prototype.$t("You have a shared payment in your cart, which can't be mixed with other sales Please, finalize the current cart and try again"));
                return false;
            }
        }
        return true;
    }

    addSaleToCart(sale: any, dom: any) {
        let cart = this.getCart();
        if (cart === null) {
            cart = {
                sales: [this.createSaleGroup(sale)],
                sItems: [],
                total: sale.pending,
                count: 1
            };
            this.saveCart(cart);
            return;
        }

        if (sale.groupId) {
            const group = cart.sales.find(y => y.groupId === sale.groupId);
            if (group) {
                if (group.saleIds.findIndex(n => n === sale.id) === -1) {
                    this.addSaleToGroup(sale, group);
                }
                else {
                    return;
                }
            } else {
                if (!this.checkMixedPaymentTypes(cart, sale)) return;
                cart.sales.push(this.createSaleGroup(sale));
            }
        }
        else {
            const found = cart.sales.find(y => y.id == sale.id);
            if (found) {
                return;
            } else {
                if (!this.checkMixedPaymentTypes(cart, sale)) return;
                cart.sales.push(this.createSaleGroup(sale));
            }
        }

        this.updateCart(cart);
        this.animationToCart(dom);
    }

    private async cancelServer(sale: any) {

        for (const cancelUrl of sale.cancelUrls) {
            const url = `${Settings.HostName}/api/${cancelUrl}`;
            const data = await new CrudService().httpPost(url);
            if (data == null || data.ok === false) {
                console.error("Crash cancelling in cart " + data.errorMsg);
                return false;
            }
        }
        return true;
    }

    async removeSaleFromCart(saleId: number, cancelServer: boolean = false) {
        const cart = this.getCart();
        if (cart === null) {
            return;
        }

        const sale = cart.sales.find(y =>
            y.saleIds.findIndex(id => id === saleId) !== -1
        );

        if (sale) {
            if (cancelServer && sale.cancelUrls.length) {
                const r = this.cancelServer(sale);
                if (!r) {
                    return;
                }
            }
            if (sale.groupId) {
                cart.sales = cart.sales.filter(el => el.groupId !== sale.groupId);
            }
            else {
                cart.sales = cart.sales.filter(el => el.id !== sale.id);
            }
            this.updateCart(cart);
        }
    }

    async removeSaleItemFromCart(saleItem: any) {
        const cart = this.getCart();
        if (cart === null) {
            return;
        }

        const cartSaleItem = this.findSaleItem(cart, saleItem);
        cart.sItems = cart.sItems.filter(el => el !== cartSaleItem);
        this.updateCart(cart);
    }



    async modifyUnits(mode: ModifyMode, saleItem: SaleItem, units: number) {
        const cart = this.getCart();
        if (cart === null) {
            return;
        }

        const found = this.findSaleItem(cart, saleItem);
        if (found === undefined && found === null) {
            return;
        }

        found.saleId = saleItem.saleId;
        if (mode === ModifyMode.Decrement) {
            found.units = found.units <= 1 ? 0 : found.units - units;
        } else if (mode === ModifyMode.Increment) {
            found.units += units;
            if ((await this.stockIsAvailable(found, cart, true)) === false) {
                return;
            }
        } else {
            found.units = units;
        }


        found.subTotal = found.product.grossPrice * found.units;
        found.netSubTotal = found.product.netPrice * found.units;
        found.totalWithDiscount = found.subTotal; // - discount

        if (found.units === 0) {
            this.removeSaleItemFromCart(found);
            return;
        }
        this.updateCart(cart);
    }

    private async addToCart(saleItem: SaleItem, dom: any) {
        let cart = this.getCart();
        if (cart === null) {
            cart = {
                sItems: [],
                sales: [],
                total: 0,
                count: 0
            };
        }
        
        const found = this.findSaleItem(cart, saleItem);
        if (found !== undefined && found !== null) {
            this.modifyUnits(ModifyMode.Increment, saleItem, saleItem.units);
            return;
        }

        const stockOk = await this.stockIsAvailable(saleItem, cart, false);
        if (stockOk === true) {
            cart.sItems.push(saleItem);
            this.updateCart(cart);
            this.animationToCart(dom);
        }

    }

    private async stockIsAvailable(s: SaleItem, cart: any, updating: boolean) {
        if (s.product === null || s.product === undefined) {
            console.error("no product in saleitem");
            return false;
        }

        if (s.product.hasStock === true) {
            const url = `${Settings.HostName}/api/publicpayments/check-stock`;
            const tmpCart: any = [];
            if (updating === false) {
                for (const item of cart.sItems) {
                    tmpCart.push({ units: item.units, temp: item.temp, stockParentId: item.stockParentId, stockParentName: item.stockParentName, stockDetail: item.stockDetail, productId: item.productId });
                }
            }
            tmpCart.push({ units: s.units, temp: s.temp, stockParentId: s.stockParentId, stockParentName: s.stockParentName, stockDetail: s.stockDetail, productId: s.productId });

            const data = await new CrudService().httpPost(url, {
                customerId: s.customerId,
                saleItemsDto: tmpCart,
            });

            if (data == null || data.ok === false) {
                store.commit("openGlobalError", data.error);
                return false;
            }
            if (data.ok === undefined) {
                store.commit("openGlobalError", "Crash");
                return false;
            }
        }
        return true;
    }




    private updateCart(cart: any) {
        const salesTotal = cart.sales.reduce((a, b) => a + b.pending, 0);
        cart.total = cart.sItems.reduce((a, b) => a + b.totalWithDiscount, 0) + salesTotal;

        const salesNetTotal = cart.sales.reduce((a, b) => a + b.netPending, 0);
        cart.netTotal = cart.sItems.reduce((a, b) => a + b.netTotalWithDiscount, 0) + salesNetTotal;

        cart.paid = cart.sales.reduce((a, b) => a + b.paid, 0);
        cart.count = cart.sItems.reduce((a, b) => a + b.units, 0) + cart.sales.length;
        this.saveCart(cart);
    }

    private findSaleItem(cart: any, saleItem: SaleItem) {
        const found = cart.sItems.find((y: { productId: number; parentId: number | null | undefined; parentName: any; }) =>
            y.productId === saleItem.productId &&
            y.parentId === saleItem.parentId &&
            y.parentName === saleItem.parentName);
        return found;
    }

    removeCart() {
        LocalStorage.delete("crt");
        store.commit("setCart", null);
    }

    private saveCart(cart: any) {
        const expiry = DateHelper.addDays(new Date(), 24);
        LocalStorage.delete("crt");
        LocalStorage.addWithTimeout("crt", cart, expiry != null && expiry !== undefined ? expiry : new Date());
        store.commit("setCart", cart);
    }

    private getCart() {
        const sCrt = LocalStorage.getWithTimeout("crt");
        if (sCrt !== null && sCrt !== "") {
            return sCrt;
        }
        return null;
    }

    private getLoggedCustomer() {
        let cid = -1;
        const sid = LocalStorage.getWithTimeout("cc");
        if (sid !== undefined && sid !== null) {
            cid = JSON.parse(sid).id;
        }
        return cid;
    }
}