
import { CrudService } from "../core/services/crudService";
import { Settings, TenantSettings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { LocalStorage } from "../core/localStorage";
import { Format } from "../core/helpers/formatHelper";
import InputText from "../components/form/inputText.vue";
import CreditCardForm from "../components/form/creditCardForm.vue";
import BookingSummary from "../components/bookingSummary/bookingSummary.vue";
import Popup from "../components/popup/popup.vue";
import Checkbox from "../components/form/checkBox.vue";
import TriggerAnchor from "../components/triggerAnchor/triggerAnchor.vue";
import { DateHelper } from "../core/helpers/dateHelper";
import LookUp from "../components/form/lookUp.vue";
import { BookingPaymentType, BookingTeam } from "../core/constants";
import { ShopService } from "../core/services/shopService";

@Component({
  components: {
    InputText,
    Popup,
    Checkbox,
    CreditCardForm,
    TriggerAnchor,
    LookUp,
    BookingSummary,
  },
})
export default class BookingJoinPage extends Vue {
  settings: TenantSettings = {};
  error: string = "";
  data: any = null;
  booking: any = null;
  blocks: any = null;
  addPlayerPopup: boolean = false;
  players: any[] = [];
  owner: any | null = null;
  team1Selected: boolean = false;
  team2Selected: boolean = false;
  priceStr: string = "";
  price: number = 0;
  allreadyIn: boolean = false;
  sale: any = null;

  async created() {
    this.settings = this.$store.state.settings;
    let sid = LocalStorage.getWithTimeout("cc");
    if (Format.IsNull(sid)) {
      this.$store.commit("openLogin", { backUrl: this.$route.path });
      return;
    }

    this.owner = JSON.parse(sid);

    const query = this.$route.params.bookingId;
    if (Format.IsNull(query)) {
      this.$router.push("bookingtypes");
      return;
    }

    this.loadBooking();
  }

  async loadBooking() {
    const url = `${Settings.HostName}/api/publicbookings/info/${this.$route.params.bookingId}`;
    const res = await new CrudService().httpGet(url);
    if (res == null) {
      this.$store.commit("openGlobalError", this.$t("crash"));
      return;
    }

    if (res.ok === false) {
      this.$store.commit("openGlobalError", res.errorMsg);
      return;
    }

    this.booking = res.model;
    if (this.booking.isPast) {
      return this.$router.push("/booking-results/" + this.booking.id);
    }

    this.booking.date = res.model.dtStart;
    this.booking.resources = res.model.resourceName;
    this.players = res.model.players;
    this.players.sort((a, b) => a.team - b.team);
    const parti = this.players.find((y) => y.customerId === this.owner.id);

    if (parti !== undefined) {
      this.allreadyIn = true;
      this.price = parti.totalDebts;
      // this.sale = {
      //   id: parti.saleIds[0],
      //   description: "// todo: download",
      //   totalWithDiscount: parti.totalDebts,
      //   totalPaid: 0, // todo
      //   saleNumber: 8322,
      // };
      return;
    }

    this.loadPrice(this.owner.id, res.model.paymentType as BookingPaymentType);
  }

  mounted() {
    window.scrollTo(0, 0);
  }

  canJoinTeam(t: BookingTeam): boolean {
    const count = this.players.filter(
      (y) => y.team === t && y.isPlaceholder
    ).length;
    if (count === 0) {
      return false;
    }
    return true;
  }

  canJoin(): boolean {
    return (
      this.booking.allowJoin === true &&
      this.allreadyIn === false &&
      this.players.find((y) => y.isPlaceholder === true) !== undefined
    );
  }

  getTeam(team: BookingTeam) {
    return team === BookingTeam.Team1
      ? this.$t("Team") + " " + 1
      : this.$t("Team") + " " + 2;
  }

  getPaymentDeadline() {
    if (this.booking == null) {
      return;
    }
    const bookingDate = new Date(this.booking.date);
    const days = DateHelper.getDaysBetweenDates(new Date(), bookingDate);
    if (days >= 6) {
      return DateHelper.beautifyDate(this.booking.date, true);
    }
    if (process.env.NODE_ENV === "development") {
      bookingDate.setHours(bookingDate.getHours());
    } else {
      // 4 hours after booking
      bookingDate.setHours(bookingDate.getHours() + 4);
    }
    return DateHelper.beautifyDate(bookingDate.toString(), true);
  }

  getImagePath(img: string) {
    if (img === null || img === undefined || img === "") {
      return this.settings.Uploads + "/" + this.settings.Mainlogo;
    }
    return this.settings.Uploads + "/" + img;
  }

  getTotalLabel() {
    return this.$t("Pay") + " " + this.priceStr;
  }

  join() {
    if (Format.IsNull(this.owner)) {
      this.$store.commit("openLogin", { backUrl: this.$route.path });
      return;
    }
    const found = this.players.find((y) => y.customerId === this.owner.id);
    if (!Format.IsNull(found)) {
      this.error = this.$t("You are already in").toString();
      this.$store.commit("openGlobalError", this.error);
      return;
    }

    const canJoin1 = this.canJoinTeam(BookingTeam.Team1);
    const canJoin2 = this.canJoinTeam(BookingTeam.Team2);

    if (canJoin1 && canJoin2) {
      this.addPlayerPopup = true;
    } else if (canJoin1) {
      this.team1Selected = true;
      this.team2Selected = false;
      this.addPlayer();
    } else if (canJoin2) {
      this.team1Selected = false;
      this.team2Selected = true;
      this.addPlayer();
    } else {
      this.error = this.$t("Max players reached").toString();
      this.$store.commit("openGlobalError", this.error);
    }
  }

  async addPlayer() {
    this.addPlayerPopup = false;

    const team = this.team1Selected ? BookingTeam.Team1 : BookingTeam.Team2;
    // prebook
    const res = await this.prebookPlace(team);
    if (res === false) {
      return;
    }

    const idx = this.players.findIndex(
      (y) => y.isPlaceholder === true && y.team === team
    );

    this.$set(this.players, idx, {
      name: this.owner.name,
      customerId: this.owner.id,
      logo: this.owner.logo,
      team: team,
      isPlaceholder: false,
    });
  }

  async loadPrice(customerId: number, paymentType: BookingPaymentType) {
    if (paymentType === BookingPaymentType.Full) {
      this.price = 0;
      this.priceStr = "";
      return;
    }

    const url = `${Settings.HostName}/api/publicbookings/loadprice`;
    const res = await new CrudService().httpPost(url, {
      startDate: this.booking.date,
      bookingTypeId: this.booking.bookingTypeId,
      duration: this.booking.duration,
      customerId: customerId,
      resourceId: parseInt(this.booking.resourceIds[0]),
    });

    if (Format.IsNull(res)) {
      this.$store.commit("openGlobalError", this.$t("crash"));
      return;
    }

    if (res.ok === false) {
      this.error = res.errorMsg;
      this.$store.commit("openGlobalError", this.error);
      return;
    }

    const p = this.$store.state.settings.PriceWithTaxes
      ? res.price.price
      : res.price.netPrice;

    if (paymentType === BookingPaymentType.Shared) {
      this.price = p / this.booking.maxParticipants;
    } else {
      this.price = p;
    }
    this.priceStr = Format.formatCurrency(this.price.toString());
  }

  goToPay(e: any) {
    this.sale.title = this.sale.description;
    this.sale.cancelUrl = `publicbookings/cancel/${this.booking.id}/${this.owner.id}`;

    new ShopService().addSaleToCart(this.sale, e.currentTarget);
    this.$router.push("/checkout");
  }

  paymentPending() {
    return this.$router.push("/customer-bookings");
  }

  async prebookPlace(team: BookingTeam) {
    this.error = "";
    this.sale = null;

    let url = `${Settings.HostName}/api/publicbookings/byplace-prebook-place/${
      this.owner.id
    }/${this.booking.id}/${this.getOrigin()}`;

    const res = await new CrudService().httpPost(url, { team });

    if (res == null || res == undefined) {
      console.error("Crash getting bookings by place");
      this.error = this.$t("Crash").toString();
      this.$store.commit("openGlobalError", this.error);
      return false;
    }

    if (res.ok === false) {
      this.error = res.errorMsg;
      this.$store.commit("openGlobalError", this.error);
      return false;
    }

    if (res.model.serviceFree === true) {
      this.$router.push("/customer-bookings");
      return true;
    }
    this.sale = res.model;
    this.price = this.sale.totalWithDiscount;
    return true;
  }

  getOrigin() {
    return Format.getOrigin();
  }
}
